import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Yhats() {
  return (
    <Layout>
      <GetItems search="Youth Hats" title="Youth Hats" />
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./accessories/yhats'} title="STM Quality Youth Hats: Positivity and Awareness" description={"Shopping for youth hats to promote Stronger Than Medicine (STM)."} />)